import * as React from 'react';
import { useIntl } from 'react-intl';

interface IProps {
    label: string;
    value: string;
}

const NumbersCard = ({ label, value }: IProps) => {
    return (
        <section className="story-numbers">
            <label>{label}</label>
            <h1>{value}</h1>
        </section>
    );
};

export default function Footer() {
    const { formatMessage } = useIntl();

    return (
        <section className="container-fluid pt-5 bg-black text-white" id="section-two">
            <div className="row">
                <div className="col-12">
                    <div className="container text-center">
                        <div className="py-3 text-smaller">
                            &copy; 2024 {formatMessage({ id: 'footer.copyright' })}{' '}
                            <a href="https://asigmagroup.com" target="_blank" rel="noopener noreferrer">
                                {formatMessage({ id: 'footer.asigma' })}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
