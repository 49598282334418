import * as React from "react";
import { useIntl } from "react-intl";

export default function Home() {
  const { formatMessage } = useIntl();

  return (
      <section className="container py-5 mt-5" id="home">
        <div className="row align-items-center home">
          <div className="col-12 col-lg-6 pe-lg-5 mb-4 mb-lg-0 align-self-lg-start pt-7 call-to-action">
            <h1 className="section-title fs-3 fs-md-4 fs-lg-5">
              {formatMessage({ id: "home.title" })}
            </h1>
            <div className="section-description fs-md-5">
              {formatMessage({ id: "home.description" })}
            </div>
            <a href="opportunities" className="btn btn-dark my-3 my-lg-5">
              {formatMessage({ id: "home.cta" })}
            </a>
          </div>
          <div className="col-12 col-lg-6">
            <div className="row g-2">
              <div className="col-6 d-flex justify-content-center align-items-center">
                <img
                    alt={formatMessage({ id: "home.images.image1Alt" })}
                    src="/images/1.png"
                    className="img-fluid square-img"
                />
              </div>
              <div className="col-6 d-flex justify-content-center align-items-center">
                <img
                    alt={formatMessage({ id: "home.images.image2Alt" })}
                    src="/images/2.png"
                    className="img-fluid square-img"
                />
              </div>
              <div className="col-6 d-flex justify-content-center align-items-center">
                <img
                    alt={formatMessage({ id: "home.images.image3Alt" })}
                    src="/images/3.png"
                    className="img-fluid square-img"
                />
              </div>
              <div className="col-6 d-flex justify-content-center align-items-center">
                <img
                    alt={formatMessage({ id: "home.images.image4Alt" })}
                    src="/images/4.png"
                    className="img-fluid square-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
