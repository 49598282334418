import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useIntl } from "react-intl";

export default function FAQs() {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const { formatMessage } = useIntl();

  const handleChange = (panel: string) => (event: React.SyntheticEvent) => {
    setExpanded(expanded === panel ? false : panel);
  };

  return (
      <section className="container-fluid py-5 my-5 bg-white" id="faqs">
        <div className="row">
          <div className="col-12">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4 order-lg-1 order-2">
                  <img
                      className="mx-auto d-block"
                      alt=""
                      style={{ width: "80%", height: "auto" }}
                      src="/images/5.png"
                  />
                </div>
                <div className="col-12 col-lg-8 order-lg-2 order-1">
                  <h3>
                    {formatMessage({ id: "faqs.title" })} <strong>{formatMessage({ id: "faqs.strong" })}</strong>
                  </h3>
                  <hr />
                  <Accordion
                      elevation={0}
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                        className="accordion-summary"
                        expandIcon={
                          expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                        }
                        aria-controls={"panel1-content"}
                        id="panel1-header"
                    >
                      <div className="summary-text">
                        {formatMessage({ id: "faqs.question1" })}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {formatMessage({ id: "faqs.answer1" })}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                      elevation={0}
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                        style={{ paddingTop: "15px", paddingBottom: "15px" }}
                        expandIcon={
                          expanded === "panel2" ? <RemoveIcon /> : <AddIcon />
                        }
                        aria-controls={"panel2-content"}
                        id="panel2-header"
                    >
                      <div style={{ width: "80%" }}>
                        <strong>{formatMessage({ id: "faqs.question2" })}</strong>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {formatMessage({ id: "faqs.answer2" })}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                      elevation={0}
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                  >
                    <AccordionSummary
                        style={{ paddingTop: "15px", paddingBottom: "15px" }}
                        expandIcon={
                          expanded === "panel3" ? <RemoveIcon /> : <AddIcon />
                        }
                        aria-controls={"panel3-content"}
                        id="panel3-header"
                    >
                      <div style={{ width: "80%" }}>
                        <strong>{formatMessage({ id: "faqs.question3" })}</strong>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {formatMessage({ id: "faqs.answer3" })}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                      elevation={0}
                      expanded={expanded === "panel4"}
                      onChange={handleChange("panel4")}
                  >
                    <AccordionSummary
                        style={{ paddingTop: "15px", paddingBottom: "5px" }}
                        expandIcon={
                          expanded === "panel4" ? <RemoveIcon /> : <AddIcon />
                        }
                        aria-controls={"panel4-content"}
                        id="panel4-header"
                    >
                      <div style={{ width: "80%" }}>
                        <strong>{formatMessage({ id: "faqs.question4" })}</strong>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {formatMessage({ id: "faqs.answer4" })}
                    </AccordionDetails>
                  </Accordion>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
