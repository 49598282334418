import React, { FC, createContext, useContext, ReactNode } from "react";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

type Language = "fr" | "en" | "ar";

interface I18nContextProps {
    selectedLang: Language;
    setLanguage: (lang: Language) => void;
}

const initialState: I18nContextProps = {
    selectedLang: "en",
    setLanguage: () => {},
};

const I18nContext = createContext<I18nContextProps>(initialState);

const getConfig = (): Language => {
    const storedConfig = localStorage.getItem(I18N_CONFIG_KEY);
    if (storedConfig) {
        try {
            const { selectedLang } = JSON.parse(storedConfig) as {
                selectedLang: Language;
            };
            return selectedLang;
        } catch (error) {
            console.error("Error parsing i18n config from localStorage:", error);
        }
    }
    return initialState.selectedLang;
};

const saveConfig = (lang: Language) => {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
};

const getBrowserLanguage = (): Language => {
    const browserLang = navigator.language;
    const lang = browserLang.split("-")[0];
    return lang === "fr" || lang === "ar" ? lang : "en";
};

export const useLang = (): Language => {
    const context = useContext(I18nContext);
    if (!context) {
        throw new Error("useLang must be used within a BlackI18nProvider");
    }
    return context.selectedLang;
};

export const useSetLanguage = () => {
    const context = useContext(I18nContext);
    if (!context) {
        throw new Error("useSetLanguage must be used within a BlackI18nProvider");
    }
    return context.setLanguage;
};

const BlackI18nProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [selectedLang, setSelectedLang] = React.useState<Language>(
        getConfig() || getBrowserLanguage()
    );

    const setLanguage = (lang: Language) => {
        saveConfig(lang);
        setSelectedLang(lang);
    };

    return (
        <I18nContext.Provider value={{ selectedLang, setLanguage }}>
            {children}
        </I18nContext.Provider>
    );
};

export { BlackI18nProvider, type Language };
