import React from "react";
import { useIntl } from "react-intl";

import bgImage1 from "../../images/7.jpg";
import bgImage2 from "../../images/8.jpg";
import bgImage3 from "../../images/9.jpg";

export default function Value() {
  const { formatMessage } = useIntl();

  return (
      <section className="container py-5 mt-5" id="value">
        <div className="row align-items-center">
          <div className="col-12">
            <label className="text-uppercase fs-6 my-3">
              {formatMessage({ id: "value.title" })}
            </label>
            <h4 className="pb-4">{formatMessage({ id: "value.header" })}</h4>

            <div className="row">
              <div className="col-12 col-lg-4 rounded-5">
                <div
                    className="collage-card text-white mx-auto d-block"
                    style={{ backgroundImage: `url(${bgImage1})` }}
                >
                  <div className="p-4 overlay">
                    <h5 className="pb-3">
                      {formatMessage({ id: "value.investors.title" })}
                    </h5>
                    <p className="pb-3">
                      {formatMessage({ id: "value.investors.description" })}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 rounded-3">
                <div
                    className="collage-card text-white mx-auto d-block"
                    style={{ backgroundImage: `url(${bgImage2})` }}
                >
                  <div className="p-4 overlay">
                    <h5 className="pb-3">
                      {formatMessage({ id: "value.businesses.title" })}
                    </h5>
                    <p className="pb-3">
                      {formatMessage({ id: "value.businesses.description" })}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 rounded-3">
                <div
                    className="collage-card text-white mx-auto d-block"
                    style={{ backgroundImage: `url(${bgImage3})` }}
                >
                  <div className="p-4 overlay">
                    <h5 className="pb-3">
                      {formatMessage({ id: "value.serviceProviders.title" })}
                    </h5>
                    <p className="pb-3">
                      {formatMessage({ id: "value.serviceProviders.description" })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
