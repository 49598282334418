import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useClientCredentialsToken from "../../hooks/useClientCredentialsToken";
import { env } from "../../data/constants";
import { useIntl } from "react-intl";
import { Dropdown, Nav } from "react-bootstrap";
import {Language, useLang, useSetLanguage} from "../../_theme/i18n/blackI18n";

interface NavLink {
  path: string;
  label: string;
}

interface NavLinksProps {
  activeLink: string;
  handleLinkClick: (link: string) => void;
  links: NavLink[];
}

const NavLinks: React.FC<NavLinksProps> = ({
                                             activeLink,
                                             handleLinkClick,
                                             links,
                                           }) => {
  return (
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        {links.map((link) => (
            <li className="nav-item" key={link.path}>
              <Link
                  className={`nav-link fw-medium text-hover-danger ${
                      activeLink === link.path ? "text-danger" : ""
                  }`}
                  aria-current="page"
                  to={link.path}
                  onClick={() => handleLinkClick(link.path)}
              >
                {link.label}
              </Link>
            </li>
        ))}
      </ul>
  );
};

export default function PageHeader() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState<string>("/#home");
  const { error } = useClientCredentialsToken();
  const { formatMessage } = useIntl();
  const currentLang = useLang();
  const setLanguage = useSetLanguage();

  useEffect(() => {
    if (location.pathname === "/" && !location.hash) {
      setActiveLink("/#home");
    } else {
      setActiveLink(
          location.hash ? location.pathname + location.hash : location.pathname
      );
    }
  }, [location.pathname, location.hash]);

  const handleLinkClick = (link: string) => {
    setActiveLink(link);
  };

  const links = [
    { path: "/#home", label: formatMessage({ id: "navLinks.home" }) },
    { path: "/#about", label: formatMessage({ id: "navLinks.about" }) },
    { path: "/#features", label: formatMessage({ id: "navLinks.features" }) },
    { path: "/#value", label: formatMessage({ id: "navLinks.value" }) },
    { path: "/#faqs", label: formatMessage({ id: "navLinks.faqs" }) },
    {
      path: "/opportunities",
      label: formatMessage({ id: "navLinks.opportunities" }),
    },
  ];

  const availableLanguages = [
    { code: "en", label: formatMessage({ id: "lang.english" }) },
    { code: "fr", label: formatMessage({ id: "lang.french" }) },
    { code: "ar", label: formatMessage({ id: "lang.arabic" }) },
  ];

  return (
      <nav className="navbar navbar-expand-lg bg-white fixed-top border-bottom">
        <div className="container">
          <Link className="navbar-brand" to="/#home">
            <img
                src={"logo.png"}
                alt={formatMessage({ id: "navbar.logoAlt" })}
                className="navbar-logo"
            />
          </Link>
          <button
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <NavLinks
                activeLink={activeLink}
                handleLinkClick={handleLinkClick}
                links={links}
            />

            <div className="d-flex justify-content-center align-items-center gap-3">
              {/* Language Dropdown */}
              <Dropdown>
                <Dropdown.Toggle variant="outline-danger" id="languageDropdown">
                  <i className="bi bi-globe2 me-1"></i>
                  {currentLang.toUpperCase()}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {availableLanguages.map((lang) => (
                      <Dropdown.Item
                          key={lang.code}
                          onClick={() => setLanguage(lang.code as Language)}
                      >
                        {lang.label}
                      </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              {/* Login Button */}
              <a
                  rel="noreferrer"
                  href={`${env.LoginUrl || "#"}`}
                  className="btn btn-outline-danger"
              >
                {formatMessage({ id: "button.login" })}
              </a>
              {/* Create Account Button */}
              <a
                  rel="noreferrer"
                  href={`${env.LoginUrl || "#"}`}
                  className="btn btn-danger"
              >
                {formatMessage({ id: "button.createAccount" })}
              </a>
            </div>
          </div>
        </div>
      </nav>
  );
}
