import PageHeader from "../../components/PageHeader";
import React, {useState} from "react";
import Footer from "../../components/Footer";
import OpportunitiesHeroSection from "../../components/OpportunitiesHeroSection";
import OpportunitiesList from "../../components/OpportunitiesList";


function Opportunities() {
    const [filters, setFilters] = useState({
        search: "",
        sectors: [],
        countries: [],
        startDatePostedRange: "",
        endDatePostedRange: "",
        amount: "",
    });
    const handleFiltersChange = (updatedFilters: any) => {
        setFilters({ ...filters, ...updatedFilters });
    };
  return (
    <div>
      <PageHeader />
      <OpportunitiesHeroSection filters={filters} setFilters={handleFiltersChange}/>
      <OpportunitiesList filters={filters}/>
      <Footer />
    </div>
  );
}
export default Opportunities;
