import * as React from "react";
import {Link} from "react-router-dom";
import OpportunityCard from "./OpportunityCard";
import {useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";

const OpportunitiesList = ({filters}:any) => {

  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchCalls = async () => {
    try {
      const queryParams = new URLSearchParams();

      if (filters.search) queryParams.append("search_param", filters.search);
      if (filters.sectors.length > 0) {
        filters.sectors.forEach((sector:string) => queryParams.append("sectors", sector));
      }
      if (filters.countries.length > 0)
        filters.countries.forEach((country:string) => queryParams.append("country", country));
      if (filters.startDatePostedRange)
        queryParams.append("start_date", filters.startDatePostedRange);
      if (filters.endDatePostedRange)
        queryParams.append("end_date", filters.endDatePostedRange);
      if (filters.amount) queryParams.append("call_amount", filters.amount);

      const response = await fetch(`https://test.financiers.black.asigmagroup.com/api/v1/calls/?${queryParams.toString()}`);
      const data = await response.json();
      setJobs(data.result.calls);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCalls();
  }, [filters]);

  if (isLoading) {
    return (
        <div className="text-center pt-5 vh-100">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
    );
  }

  if (error) {
    return <div className="text-center text-danger pt-5">Error: {error}</div>;
  }

  if (jobs.length === 0) {
    return (
        <div className="text-center align-items-center py-5">
          <h6>There are currently no opportunities for the applied filters</h6>
        </div>
    );
  }

  return (
      <section
          className="container-fluid py-4"
          style={{backgroundColor: "#F5F5F5"}}
          id="value"
      >
        <div className="row">
          <div className="col-12">
            <div className="container">
              <div className="row">
                {jobs.map((job: any, index) => (
                    <div key={index} className="col-12 col-lg-4">
                      <Link
                          to={`https://issho.asigmagroup.com/${job.url}`}
                          style={{textDecoration: "none", color: "inherit"}}
                      >
                        <OpportunityCard
                            financier={job.financier}
                            financier_id={job.financier_id}
                            financing_type={job.financing_type}
                            logo={job.logo}
                            title={job.name}
                            amount={job.call_amount}
                            tags={job.sectors}
                            description={job.description}
                            deadline={job.deadline}
                            location=""
                        />
                      </Link>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

export default OpportunitiesList;