import React from "react";
import { useIntl } from "react-intl";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function Features() {
  const { formatMessage } = useIntl();

  return (
      <section
          className="d-flex flex-column align-items-center justify-content-center mt-5"
          id="features"
      >
        <div className="pe-lg-5 mb-3 d-flex flex-column align-items-center justify-content-center px-3 features-text">
          <label className="text-uppercase fs-6 my-3 fw-bold w-100">
            {formatMessage({ id: "features.title" })}
          </label>
          <h4 className="max-width-700px">
            {formatMessage({ id: "features.header" })}
          </h4>
          <div className="section-description py-3 max-width-900px">
            {formatMessage({ id: "features.description" })}
          </div>
        </div>
        {/* Collage Section */}
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-center px-3 gap-4">
          {/* Pipeline Development */}
          <div className="bg-pink p-3 rounded-3 w-100 max-width-350 min-height-200px">
            <div className="d-flex gap-2 justify-content-start align-items-center mb-2">
              <img
                  alt={formatMessage({ id: "features.pipeline.title" })}
                  src={`${process.env.PUBLIC_URL}/images/pipeline-image.svg`}
              />
              <h6>{formatMessage({ id: "features.pipeline.title" })}</h6>
            </div>
            <p className="card-text h-100px">
              {formatMessage({ id: "features.pipeline.description" })}
            </p>
            <div className="d-flex justify-content-end ">
              <div className="border border-2 rounded-circle border-white">
                <ArrowForwardIcon />
              </div>
            </div>
          </div>

          {/* Assessment of Business Needs */}
          <div className="bg-black p-3 rounded-3 w-100 max-width-350">
            <div className="d-flex gap-2 justify-content-start align-items-center mb-2">
              <img
                  alt={formatMessage({ id: "features.businessNeeds.title" })}
                  src={`${process.env.PUBLIC_URL}/images/globe-image.svg`}
              />
              <h6>{formatMessage({ id: "features.businessNeeds.title" })}</h6>
            </div>
            <p className="card-text">
              {formatMessage({ id: "features.businessNeeds.description" })}
            </p>
            <div className="d-flex justify-content-end ">
              <div className="border border-2 rounded-circle border-white">
                <ArrowForwardIcon />
              </div>
            </div>
          </div>

          {/* Facilitating Introductions and Engagements */}
          <div className="bg-pink p-3 rounded-3 w-100 max-width-350 min-height-200px">
            <div className="d-flex gap-2 justify-content-start align-items-center mb-2">
              <img
                  alt={formatMessage({ id: "features.engagements.title" })}
                  src={`${process.env.PUBLIC_URL}/images/checked-task-image.svg`}
              />
              <h6>{formatMessage({ id: "features.engagements.title" })}</h6>
            </div>
            <p className="card-text h-100px">
              {formatMessage({ id: "features.engagements.description" })}
            </p>
            <div className="d-flex justify-content-end ">
              <div className="border border-2 rounded-circle border-white">
                <ArrowForwardIcon />
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
