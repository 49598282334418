import * as React from "react";
import { useIntl } from "react-intl";

export default function About() {
  const { formatMessage } = useIntl();

  return (
      <section className="container-fluid py-5 mt-5 bg-black text-white" id="about">
        <div className="row">
          <div className="col-12">
            <div className="container">
              <div className="row align-items-center">
                {/* Image section with floating behavior only on large screens */}
                <div className="col-12 col-lg-6 order-lg-1 order-2">
                  <img
                      className="d-none d-lg-block floating-image"
                      alt="About Image"
                      src="/images/6.png"
                      style={{ width: "100%", height: "auto" }}
                  />
                  <img
                      className="d-block d-lg-none"
                      alt="About Image"
                      src="/images/6.png"
                      style={{ width: "100%", height: "auto" }}
                  />
                </div>

                <div className="col-12 col-lg-6 order-lg-2 order-1">
                  <label className="text-uppercase fs-6 my-3">
                    {formatMessage({ id: "about.title" })}
                  </label>
                  <h3>
                    {formatMessage({ id: "about.header" })}
                  </h3>
                  <div className="section-description py-3">
                    <p>
                      {formatMessage({ id: "about.description" })}
                    </p>
                    <p>
                      {formatMessage({ id: "about.description2" })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
