import { useEffect, useRef, useState } from "react";
import ButtonWithIcon from "./ButtonWithIcon";

import { AUTH_TOKEN_KEY, env } from "../../data/constants";
import { sectorCategoryIcons } from "../../data/sectorCategories";

import { INameIdPair } from "../modules/shared/components/INameIdPair";

import { AllInbox, FilterList } from "@mui/icons-material";

import { ListPageFilters } from "../modules/opportunity/components/Filters";

import Skeleton from "@mui/material/Skeleton";
import { Spinner } from "react-bootstrap";
import { useIntl } from "react-intl";

const OpportunitiesHeroSection = ({ filters, setFilters }: any) => {
  const { formatMessage } = useIntl(); // Accessing the translation function

  const [sectorCategories, setSectorCategories] = useState<
      { name: string; icon: any }[]
  >([]);
  const [activeCategory, setActiveCategory] = useState<string>("All");
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const sectorsRef = useRef<INameIdPair[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleCategoryClick = (categoryName: string) => {
    if (categoryName === formatMessage({ id: "category.moreFilters" })) {
      setShowFiltersModal(!showFiltersModal);
    } else {
      setFilters({
        sectors: categoryName === "All" ? [] : [categoryName],
        countries: [],
        amount: "",
        startDatePostedRange: "",
        endDatePostedRange: "",
      });
      setActiveCategory(categoryName);
    }
  };

  const applyFilters = (updatedFilters: any) => {
    setFilters({ ...filters, ...updatedFilters });
    setShowFiltersModal(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFilters({ ...filters, search: searchTerm });
  };

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = localStorage.getItem(AUTH_TOKEN_KEY) || "";
      const getCompanySectorsUrl = env.CompanyUrl
          ? `${env.CompanyUrl}/sectors`
          : "";

      const response = await fetch(getCompanySectorsUrl, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        sectorsRef.current = await response.json();

        const newCategories = generateSectorCategoriesWithIcons(
            sectorsRef.current
        );

        setSectorCategories(newCategories);
      } else {
        console.error("Failed to fetch sectors:", response.statusText);
      }

      setIsFetching(false);
    };

    fetchData();
  }, []);

  const generateSectorCategoriesWithIcons = (sectors: INameIdPair[]) => {
    const newCategories = [
      {
        name: formatMessage({ id: "category.all" }),
        icon: <AllInbox fontSize="small" />,
      },
    ];

    const matchedCategories = sectors
        .map((sector) => {
          const matchedCategory = sectorCategoryIcons.find((category) =>
              category.name
                  .toLowerCase()
                  .startsWith(sector.name.slice(0, 4).toLowerCase())
          );

          return matchedCategory
              ? { name: sector.name, icon: matchedCategory.icon as any }
              : null;
        })
        .filter(Boolean) as { name: string; icon: any }[];
    newCategories.push(...matchedCategories);

    newCategories.push({
      name: formatMessage({ id: "category.moreFilters" }),
      icon: <FilterList fontSize="small" />,
    });

    return newCategories;
  };

  const handleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal);
  };

  const filteredCategories =
      filters.sectors.length > 0
          ? sectorCategories.filter(
              (category) =>
                  filters.sectors.includes(category.name) ||
                  category.name === formatMessage({ id: "category.all" })
          )
          : sectorCategories;

  useEffect(() => {
    if (filters.sectors.length > 0) {
      setActiveCategory(filters.sectors[0]);
    } else {
      setActiveCategory(formatMessage({ id: "category.all" }));
    }
  }, [filters.sectors]);

  return (
      <section
          className="container-fluid pt-5 mt-5 bg-black text-white"
          id="section-three"
      >
        <div className="d-flex border-bottom pb-3 flex-column align-items-center text-center justify-content-center">
          <div className="opportunities-hero">
            <h6 className="fw-bolder text-smaller text-start text-md-center heading">
              {formatMessage({ id: "hero.title" })}
            </h6>

            <div className="text-start text-md-center description max-width-700px ">
            <span className="fs-2">
              {formatMessage({ id: "hero.description" })}
            </span>
            </div>
          </div>

          <div className="input-group w-100 opportunities-search d-flex justify-content-center py-3 max-width-700px">
            <input
                type="text"
                className="form-control py-3 ps-5 rounded-start-5 custom-focus-outline"
                placeholder={formatMessage({ id: "search.placeholder" })}
                aria-label={formatMessage({ id: "search.ariaLabel" })}
                aria-describedby="basic-addon2"
                value={searchTerm}
                onChange={handleSearchChange}
            />

            <button
                className="btn btn-danger rounded-start-0 rounded-end-5 py-3 px-5"
                type="submit"
                onClick={handleSearchSubmit}
            >
              {formatMessage({ id: "search.button" })}
            </button>
          </div>

          <div className="d-flex flex-wrap justify-content-center py-3 text-hover-danger ">
            {isFetching
                ? Array.from({ length: 3 }).map((_, index) => (
                    <Skeleton
                        key={index}
                        variant="rounded"
                        width={100}
                        height={35}
                        sx={{
                          bgcolor: "white",
                          borderRadius: "17.5px",
                          marginRight: 2,
                        }}
                    />
                ))
                : filteredCategories.map((category, index) => (
                    <div
                        key={index}
                        className={`category-item category-item-${index} ${
                            category.name !==
                            formatMessage({ id: "category.moreFilters" })
                                ? ""
                                : "more-filters"
                        }`}
                    >
                      <ButtonWithIcon
                          name={category.name}
                          icon={category.icon}
                          isActive={activeCategory === category.name}
                          handleClick={() => handleCategoryClick(category.name)}
                      />
                    </div>
                ))}
          </div>
        </div>
        <ListPageFilters
            showModal={showFiltersModal}
            handleClose={handleFiltersModal}
            companySectorsList={sectorsRef.current}
            applyFilters={applyFilters}
        />
      </section>
  );
};

export default OpportunitiesHeroSection;
