import React, { FC, ReactNode } from "react";
import { useLang } from "./blackI18n";
import { IntlProvider } from "react-intl";
import enMessages from "./messages/en.json";
import frMessages from "./messages/fr.json";
import arMessages from "./messages/ar.json";

const messages = {
    en: enMessages,
    fr: frMessages,
    ar: arMessages,
};

interface I18nProviderProps {
    children: ReactNode;
}

const I18nProvider: FC<I18nProviderProps> = ({ children }) => {
    const locale = useLang();
    const currentMessages = messages[locale];

    return (
        <IntlProvider locale={locale} messages={currentMessages}>
            {children}
        </IntlProvider>
    );
};

export { I18nProvider };
