import * as React from "react";
import { useIntl } from "react-intl";

interface IProps {
    labelId: string;
    value: string;
}

const NumbersCard = ({ labelId, value }: IProps) => {
    const { formatMessage } = useIntl();
    return (
        <section className="story-numbers">
            <label>{formatMessage({ id: labelId })}</label>
            <h1>{value}</h1>
        </section>
    );
};

export default function Story() {
    const { formatMessage } = useIntl();

    return (
        <section className="container-fluid py-5 mt-5 bg-black text-white" id="story">
            <div className="row">
                <div className="col-12">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-2">
                                <h3 className="fw-bolder">
                                    {formatMessage({ id: "story.title" })}
                                </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-lg-3">
                                <NumbersCard labelId="story.businessesHelped.label" value="72+" />
                            </div>
                            <div className="col-6 col-lg-3">
                                <NumbersCard labelId="story.financiers.label" value="45+" />
                            </div>
                            <div className="col-6 col-lg-3">
                                <NumbersCard labelId="story.serviceProviders.label" value="210+" />
                            </div>
                            <div className="col-6 col-lg-3">
                                <NumbersCard labelId="story.amountRaised.label" value="$456M" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
